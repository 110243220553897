























































































































































































































































































































































































































































































































































































































































































































































































































































































































.newHomeTwo {
	.van-swipe__indicators {
		width: 22%;
		left: 50%;
		bottom: 0.16rem;
	}

	.van-swipe__indicator {
		height: 0.04rem;
		border-right: 0;
		flex: 1;
	}

	.van-swipe__indicator:not(:last-child) {
		margin-right: 0;
	}
}

@font-size-sm: 14px;@font-size-md: 16px;@font-size-lg: 18px;@goods-action-button-danger-color: #7232dd;@goods-action-button-warning-color: #3eaf7c;