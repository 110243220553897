.menuList {
  font-family: PingFang SC;
  width: 25%;
  color: #666666;
  font-size: 0.24rem;
  transform: scale(0.916);
  text-align: center;
  margin-bottom: 0.15rem;
  text-transform: capitalize;
}
.menuList .icon {
  width: 0.8735rem;
  height: 0.8735rem;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.menuList .icon img {
  display: block;
  width: 100%;
}
